import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

import Layout from "../components/Layout";

import SEOImage from "../images/seo-images/about.png";

import "../styles/about.scss";

const About = () => {
  return (
    <Layout>
      <Helmet>
        <title>
          About Us | Branch Out Online | Website Design & Development
        </title>
        <meta
          name="description"
          content="We are all about helping you branch out online. The team at Branch Out Online are experts in Jamstack web development, creating fast, secure and beautiful websites to help your business grow."
        />
        <meta property="og:image" content={SEOImage} />
      </Helmet>
      <section className="about-us-top-container">
        <div className="about-us-top-txt-container">
          <h1>A bit about us...</h1>
          <StaticImage
            src="../images/isaac-profile.png"
            alt="Isaac Arnold, founder of Branch Out Online"
            className="about-us-top-img"
            placeholder="blurred"
          />
          <div className="about-us-top-txt">
            <p>
              Hi, I'm Isaac - founder of Branch Out Online and I want to help
              you!
            </p>
            <p>
              It's increasingly important in today's day and age to have an
              online presence for your business. This online presence begins
              with having a beautiful, fast and secure website.
            </p>
            <p>
              Your website is a place to tell your story, showcase your work and
              more importantly, sell to customers. A lot of small businesses
              avoid building websites for a number of reasons; whether that be
              the cost, the maintanence or simply because they don't understand
              the importance of a website.
            </p>
            <p>That's where I come in...</p>
          </div>
        </div>
      </section>
      <section className="about-us-second-container">
        <h2>The approach - how we do things</h2>
        <div className="approach-container">
          <div className="individual-approach app-one">
            <div className="approach-num">1</div>
            <h3>Consultation</h3>
            <p>
              We will sit down and discuss your business needs, goals and most
              importantly budget. From this conversation both of us will have an
              understanding about why your business needs a website and the
              importance it brings to helping you branch out online.
            </p>
          </div>
          <div className="individual-approach app-two">
            <div className="approach-num">2</div>
            <h3>Website/CMS Design</h3>
            <p>
              We will work closely with you to design a site you are proud of!
            </p>
            <p id="p">
              If you want to easily customise your site, when you want, then we
              will flesh out the exact requirements in this step.
            </p>
          </div>
          <div className="individual-approach app-three">
            <div className="approach-num">3</div>
            <h3>Development</h3>
            <p>
              I work my magic in development to turn your dream into a reality.
            </p>
          </div>
          <div className="individual-approach app-four">
            <div className="approach-num">4</div>
            <h3>QA Process</h3>
            <p>
              We will go through the website and make tweaks so that you are
              happy with the final result.
            </p>
          </div>
          <div className="individual-approach app-five">
            <div className="approach-num">5</div>
            <h3>Deployment</h3>
            <p>We send your site out into the big bad world!</p>
          </div>
          <div className="individual-approach">
            <Link to="/contact" className="app-btn">
              <h3>Ready to branch out online?</h3>
              <p>Get in touch today!</p>
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default About;
